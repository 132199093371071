<template>
    <div class="login-container">
        <div class="login-header">
            <v-img src="/img/login_bg.jpg"
                   gradient="to top, rgba(6, 62, 25, 0.91), rgba(38, 193, 99, 0.92)"
            ></v-img>
        </div>

        <div class="d-flex justify-center login-card-container">
            <v-form ref="form1" v-model="form1Valid" lazy-validation @submit="ok" >
                <v-card max-width="400px" class="pa-6 pt-0 login-card" elevation="3">
                    <v-card-text class="text-center">
                        <span class="title">Escolha uma nova senha para sua conta</span>
                        <v-form ref="form1" v-model="form1Valid" lazy-validation class="mt-6">
                            <v-row>
                                <v-col>
                                    <v-text-field
                                            v-model="newPassword"
                                            :append-icon="show1 ? 'mdi-eye' : 'mdi-eye-off'"
                                            :rules="[rules.required, rules.min]"
                                            :type="show1 ? 'text' : 'password'"
                                            label="Nova senha" placeholder=" "
                                            hint="No mínimo 8 caracteres" tabindex="1"
                                            @click:append="show1 = !show1"
                                    ></v-text-field>
                                </v-col>
                            </v-row>
                            <v-row>
                                <v-col>
                                    <v-text-field
                                            v-model="newPasswordConfirm"
                                            :rules="[rules.required, rules.match]"
                                            type="password" tabindex="2"
                                            label="Confirmar senha" placeholder=" "
                                    ></v-text-field>
                                </v-col>
                            </v-row>
                        </v-form>
                    </v-card-text>
                    <v-card-actions>
                        <v-spacer></v-spacer>
                        <v-btn outlined color="primary" tabindex="3" :disabled="loading" @click="ok">Entrar</v-btn>
                    </v-card-actions>
                </v-card>
            </v-form>
        </div>
    </div>
</template>

<script>
    import localforage from 'localforage';
    import {UsuarioQueries} from '../queries/UsuarioQueries';
    export default {
        name: 'NewPassword',
        data() {
            return {
                show1: false,
                show2: false,
                loading: false,
                newPassword: '',
                newPasswordConfirm: '',
                form1Valid: true,
                rules: {
                    required: (value) => !!value || 'Campo Obrigatório.',
                    min: (v) => v && v.length >= 8 || 'Mínimo 8 caracteres',
                    match: (v) => v === this.newPassword || 'Senhas não coincidem',
                },
            };
        },
        methods: {
            ok() {
                if (this.$refs.form1.validate()) {
                    this.$apollo.mutate({
                        mutation: UsuarioQueries.newPassword,
                        variables: {
                            password: this.newPassword,
                        },
                    }).then((result) => {
                        const usuario = result.data.newUsuarioPassword;
                        return localforage.setItem('usuario', usuario);
                    }).then(() => {
                        this.$router.replace({name: 'fiscalizacoes'});
                    }).catch((error) => {
                        // console.log(error)
                    });
                }
            },
        },
        mounted() {
            localforage.getItem('usuario').then((usuario) => {
                if (usuario) {
                    this.$router.replace({name: 'fiscalizacoes'});
                }
            });
        },
    };
</script>

<style>
    .login-container{
        height: 100%;
    }
    .login-header,
    .login-header .v-image{
        height: 300px;
    }
    .login-card-container{
        margin-top: -150px
    }
    @media(max-width: 600px) {
        .login-container{
            display: flex;
            justify-content: center;
            align-items: center;
        }
        .login-header{
            position: fixed;
        }
        .login-header,
        .login-header .v-image{
            height: 100%;
        }
        .login-card-container{
            margin-top: unset;
            padding: 20px;
            position: absolute;
        }
    }


    input:-webkit-autofill,
    input:-webkit-autofill:hover,
    input:-webkit-autofill:focus textarea:-webkit-autofill,
    textarea:-webkit-autofill:hover textarea:-webkit-autofill:focus,
    select:-webkit-autofill,
    select:-webkit-autofill:hover,
    select:-webkit-autofill:focus {
        -webkit-box-shadow: 0 0 0px 1000px #ffffff inset !important;
    }
</style>